import(/* webpackMode: "eager", webpackExports: ["BlockPreview"] */ "/app/components/block-preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/commit-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CopyButton"] */ "/app/components/copy-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/repo-download.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/template-changelog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/template-pricing.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Calendar"] */ "/app/components/ui/calendar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Command","CommandInput","CommandList","CommandEmpty","CommandGroup","CommandItem"] */ "/app/components/ui/command.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/ui/tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tooltip","TooltipTrigger","TooltipContent"] */ "/app/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.0_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.0_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.0_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/hooks.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/icons/icons.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-container.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-actions-copy.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-container.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/theme.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-header.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-in-reply-to.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-link.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-media-video.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/verified-badge.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-body.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-media.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-info-created-at.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-info.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-actions.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-replies.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-header.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-body.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-not-found.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/skeleton.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-skeleton.module.css");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/call-to-action-2.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/call-to-action-3.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/call-to-action-4.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/carousel-1.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/carousel-2.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/faq-1.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/feature-1.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/feature-2.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/feature-scroll-1.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/footer-6.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/header-1.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/header-3.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/header-4.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/header-5.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/hero-1.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/hero-2.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/pricing-1.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/pricing-2.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/pricing-3.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/pricing-4.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/pricing-8.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/stats-2.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/stats-3.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/stats-4.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/blocks/example/stats-5.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-beam-bidirectional.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-beam-demo.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-beam-multiple-inputs.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-beam-multiple-outputs.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-beam-unidirectional.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-1.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-10.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-2.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-3.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-4.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-5.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-6.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-7.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-8.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-feature-card-9.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-grid-pattern-demo.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/animated-list-demo.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/dot-pattern-demo.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/dot-pattern-linear-gradient.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/grid-pattern-dashed.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/grid-pattern-demo.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/grid-pattern-linear-gradient.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/particles-demo.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/example/retro-grid-demo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/registry/components/magicui/client-tweet-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dock","DockIcon"] */ "/app/registry/components/magicui/dock.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/magicui/globe.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/magicui/hero-video.tsx");
;
import(/* webpackMode: "eager" */ "/app/registry/components/magicui/magic-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/registry/components/magicui/meteors.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/registry/components/magicui/number-ticker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/registry/components/magicui/text-reveal.tsx");
